import React, {useState, useEffect, Fragment} from 'react'
import {
    Form,
    Button,
    Select,
    Input,
    Radio,
    notification,
    DatePicker,
    Checkbox,
    message,
    Badge
} from 'antd';
import { useDispatch, useSelector } from "react-redux";
import dayjs from 'dayjs';
import moment from 'moment';
import parse from 'html-react-parser';
import {CloseOutlined, CheckCircleOutlined, CloseCircleOutlined, PlusOutlined} from '@ant-design/icons';
import { addCSTaskData, updateCSTaskData, emptyAddCSTask, emptyUpdateCSTask,getAllAdminListData } from '../../actions/task_dashboard_actions';
import AttachmentAction from '../AttachmentAction';
import { capitalizeFirstLetter } from '../CommonFunctions';
import ConversationLink  from '../conversationLink';
import CopyText from '../CopyText'

const { TextArea } = Input;
const Option = Select.Option;

let url = window.location.origin + `/conversations?search=`

const CSTaskForm = (props) => {

    let { closeForm, orderId, sellerId, userId, jobId, sellerEmail, userEmail,  trackMixPanel} = props
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [data, setData] = useState([])
    const [formLoading, setFormLoading] = useState(false)
    const [title, setTitle] = useState(null)
    const [otherTitle, setOtherTitle] = useState(null)
    const [loggeedInUserData, setLoggedInUserData] = useState([])
    const [allEditAccess, setAllEditAccess] = useState(false)
    const [csType, setCSType] = useState('')
    const [notesTxt, setNotesTxt] = useState('')
    const [imagesData, setImagesData] = useState([]);
    const [disableButton, setDisableButton] = useState(false)
    const [adminList, setAdminList] = useState([]);
    const currentDateTime = moment();
     
    const getAllAdminListResponse = useSelector((state) => {
        if (state.TaskDashboard.get_all_admin_list && state.TaskDashboard.get_all_admin_list.result) {
            return state.TaskDashboard.get_all_admin_list;
        }
    });

    useEffect(() => {
        if(getAllAdminListResponse && getAllAdminListResponse?.success === true){
            if(getAllAdminListResponse?.result?.length !== 0){
                setAdminList(getAllAdminListResponse?.result)
                // adminList = getAllAdminListResponse?.result
            }
        }
    }, [getAllAdminListResponse])

    const imageChangeHandler = (newValue) => {
        setImagesData(newValue);
    }

    const actionButtonDisableHandler = (value) => {
        setDisableButton(value);
    }

    const loginResponse = useSelector((state) => {
        if (state.Common.login_user_data !== undefined) {
            return state.Common.login_user_data;
        }
    });

    const addCSTaskDataResponse = useSelector((state) => {
        if (state.TaskDashboard.add_cs_task && state.TaskDashboard.add_cs_task !== null) {
            return state.TaskDashboard.add_cs_task;
        }
    });

    const updateCSTaskDataResponse = useSelector((state) => {
        if (state.TaskDashboard.update_cs_task && state.TaskDashboard.update_cs_task.result) {
            return state.TaskDashboard.update_cs_task;
        }
    });

    useEffect(() => {
        if(addCSTaskDataResponse && addCSTaskDataResponse?.success === true){
            notification.open({
                message: 'CS Task Added successfully',
                icon: <CheckCircleOutlined style={{ color: '#7CFC00' }} />,
            });
            dispatch(emptyAddCSTask())
            if(props.getTaskDetails !== undefined){
                props.getTaskDetails()
            }
            setFormLoading(false)
            closeForm()
            
        } else if(addCSTaskDataResponse && addCSTaskDataResponse?.success === false){
            notification.open({
                message: addCSTaskDataResponse?.message ?? 'Please try again.',
                icon: <CloseCircleOutlined style={{ color: '#7CFC00' }} />,
            }); 
            dispatch(emptyAddCSTask())
        }
    }, [addCSTaskDataResponse])

    useEffect(() => {
        if(updateCSTaskDataResponse && updateCSTaskDataResponse?.success === true){
            notification.open({
                message: 'CS Task Updated successfully',
                icon: <CheckCircleOutlined style={{ color: '#7CFC00' }} />,
            });
            dispatch(emptyUpdateCSTask())
            if(props.getTaskDetails !== undefined){
                props.getTaskDetails()
            }
            setFormLoading(false)
            closeForm()
        } else if(updateCSTaskDataResponse && updateCSTaskDataResponse?.success === false){
            notification.open({
                message: updateCSTaskDataResponse?.message ?? 'Please try again.',
                icon: <CloseCircleOutlined style={{ color: '#7CFC00' }} />,
            }); 
            dispatch(emptyUpdateCSTask())
        }
    }, [updateCSTaskDataResponse])

    useEffect(() => {
        if(loginResponse?.length !== 0){
            setLoggedInUserData(loginResponse)
            if(loginResponse?.task_dashboard_access === 1 || loginResponse?.task_dashboard_access === '1'){
                setAllEditAccess(true)
            } else{
                setAllEditAccess(false)
            }
        }
    }, [loginResponse])

    useEffect(() => {
        // console.log(props.data.length !== 0, props.data)
        if(props.data.length !== 0){

            setData(props.data)
            setCSType(props.data.type)
            if(props.data.attachment !== '' && props.data.attachment !== null){
                let imagesList = props.data.attachment?.split(',')
                if(imagesList?.length !== 0){
                    imagesList = imagesList.map((url)=>{
                        let item = {
                            link: url,
                            name: url?.split("/")[url?.split("/")?.length - 1]
                        }
                        return item
                    })
                }
                setImagesData(imagesList)
            }
            form?.setFieldsValue({
                title: props.data?.title !== undefined ? props.data.title : null,
                description: props.data?.description !== undefined ? props.data.description : null,
                type: props.data?.type !== undefined ? props.data.type : null,
                assignee: props.data?.assignee !== undefined ? props.data.assignee?.split(",")?.map((item)=>parseInt(item)) : null,
                due_date: props.data?.due_date !== undefined && props?.data?.due_date !== null && props?.data?.due_date !== '' && props?.data?.due_date !== '0000-00-00 00:00:00' ? dayjs(props.data.due_date) : null,
                action_date: props.data?.action_date !== undefined && props?.data?.action_date !== null && props?.data?.action_date !== '' && props?.data?.action_date !== '0000-00-00 00:00:00' ? dayjs(props.data.action_date) : null,
                status: props.data?.status !== undefined ? props.data.status?.split(',')[0] : "Open",
                tag: props.data?.tag !== undefined ? props.data.tag : null,
                watch: props.data?.watch !== undefined ? props.data.watch?.split(',') : null,
            })
            if(
                props.data.title !== 'Seller Review' &&
                props.data.title !== 'Buyer Review' &&
                props.data.title !== 'Job Review' &&
                props.data.title !== 'Order Review'&&
                props.data.title !== 'Add Payment'&&
                props.data.title !== 'Quiet OTP'&&
                props.data.title !== 'Call Client'&&
                props.data.title !== 'Call Artist'
            ){
                form?.setFieldsValue({
                    other_title: props.data?.title,
                    title: 'Other'
                })
                setTitle('Other')
            } else{
                setTitle(props.data.title ?? null)
            }
            if(!allEditAccess){
                if(loggeedInUserData?.email?.toLowerCase() === props.data.assigned_by?.toLowerCase() ){
                    setAllEditAccess(true)
                }
            }
            // console.log(allEditAccess)
        } else{
            setData([])
            form.resetFields()
            setTitle(null)
            setOtherTitle(null)
            setAllEditAccess(true)
            setCSType('')
            setImagesData([])
            setNotesTxt('')
            setDisableButton(false)
            form?.setFieldsValue({
                status: 'Open',
                
            })
            setFormLoading(false)
        }
    }, [props.data])
    
    const formUniqueValues = (array)=>{
        let uniqueEmailArray = array.filter((email, index) => array.indexOf(email) === index);
        return uniqueEmailArray.join(',');
    }

    const handleFormSubmit = () => {
        form.validateFields().then(async values => {
            // console.log(values)
            setFormLoading(true)
            let req = {}
            let type = ''
            let currentDateTime = moment();
            let userLoginValue = capitalizeFirstLetter(loggeedInUserData?.email.split('@')[0]) + ' - ' + currentDateTime.format('DD-MM-YYYY HH:mm:ss');
            let value = '';
            if(data.notes !== '' && data.notes !== null && data.notes !== undefined) {
                value = data.notes;
    
                if(notesTxt !== '' && notesTxt !== null && notesTxt !== undefined) {
                    value = value + "\n" + "\n";
                }
            }
            
            if(notesTxt !== '' && notesTxt !== null && notesTxt !== undefined) {
                value = value + userLoginValue + "\n" + notesTxt;
            }

            if(value !== ''){
                req = {
                    ...req,
                    notes: value
                }
            }

            let attachments = ""
            if(imagesData?.length !== 0){
                attachments = imagesData?.map(d => d.link).toString()
            }
            req = {
                ...req,
                attachment: attachments
            }
            if(values?.watch !== undefined){
                req = {
                    ...req,
                    watch: values?.watch == "" || values?.watch.length ==0 ? null :  formUniqueValues(values?.watch)
                }
            }
            if(values?.other_assignee !== undefined){
                let newArray = values?.assignee?.concat(values.other_assignee)
                req = {
                    ...req,
                    assignee: formUniqueValues(newArray)
                }
            }
            if(allEditAccess){
                if(values?.assignee !== undefined){ 
                    let newArray = values.other_assignee !== undefined && values.other_assignee !== null ?  values?.assignee?.concat(values.other_assignee) : values?.assignee
                    
                    req = {
                        ...req,
                        assignee: formUniqueValues(newArray)
                    }
                }
                if(values?.status !== undefined){
                    req = {
                        ...req,
                        // status: values?.status?.join(',')
                        status: values?.status
                    }
                }
                if(values?.other_title !== undefined){
                    type = values?.type
                    req = {
                        ...req,
                        title: values?.other_title,
                        type: values?.type
                    }
                } else if(values?.title !== undefined){
                    req = {
                        ...req,
                        title: values?.title,
                        type: csType
                    }
                }
                delete values?.title
                delete values?.other_title
                delete values?.assignee
                delete values?.type
                delete values?.status
                delete values?.watch
                delete values?.other_assignee
                req = {
                    ...req,
                    ...values,
                    due_date: values?.due_date !== undefined && values?.due_date !== null && values?.due_date !== "" ?  dayjs(values?.due_date).format('YYYY-MM-DD') : null
                }
            } else{
                req = {
                    ...req,
                    status: values.status,
                    description: values.description
                }
            }
            req = {
                ...req,
                action_date: values?.action_date !== undefined && values?.action_date !== null && values?.action_date !== "" ?  dayjs(values?.action_date).format('YYYY-MM-DD') : null
            }
            // console.log(req)
            // console.log(data)
            if(data?.length === 0){
                // console.log('data empty')
                if(csType === 'Seller Id'){
                    req = {
                        ...req,
                        seller_id: sellerId, 
                    }
                } else if(csType === 'Buyer Id'){
                    req = {
                        ...req,
                        user_id: userId, 
                    }
                } else if(csType === 'Order Id'){
                    req = {
                        ...req,
                        order_id: orderId, 
                        seller_id: sellerId, 
                        user_id: userId, 
                        job_id: jobId
                    }
                } else if(csType === 'Job Id'){
                    req = {
                        ...req,
                        job_id: jobId,
                        user_id: userId
                    }
                }else if(csType === 'Add Payment'){
                    req = {
                        ...req,
                        order_id: orderId, 
                        seller_id: sellerId, 
                        user_id: userId, 
                    }
                }else if(csType === 'Quiet OTP'){
                    req = {
                        ...req,
                        order_id: orderId, 
                        seller_id: sellerId, 
                    }
                }else if(csType === 'Call Artist'){
                    req = {
                        ...req,
                        seller_id: sellerId, 
                    }
                }else if(csType === 'Call Client'){
                    req = {
                        ...req,
                        user_id: userId, 
                    }
                }
                // console.log(req, "from data empty")
                dispatch(addCSTaskData(req))
            } else{
                let newReq = {...req , logged_in_user : loggeedInUserData?.email}                
                dispatch(updateCSTaskData(newReq, data.id))
                // console.log(data.id)
            }

            trackMixPanel('Save Task', req)
            
        }).catch(errorInfo => {
            // console.log(errorInfo)
            form.scrollToField(errorInfo?.errorFields?.[0]?.name, {
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            });
        });
    }

    const handleChangeTitle = (e) => {
        setTitle(e)
        let type = ''
        if(e?.includes('Seller')){
            type = 'Seller Id'
        } else  if(e?.includes('Buyer')){
            type = 'Buyer Id'
        }  if(e?.includes('Order')){
            type = 'Order Id'
        }  if(e?.includes('Job')){
            type = 'Job Id'
        }if(e?.includes('Payment')){
            type = 'Add Payment'
        }if(e?.includes('Quiet')){
            type = 'Quiet OTP'
        }if(e?.includes('Artist')){
            type = 'Call Artist'
        }if(e?.includes('Client')){
            type = 'Call Client'
        }
        setCSType(type)
    }

    const disabledDate = (current) => {
        // Can not select days after today
        return  current < moment().startOf('day');
    }

    const copyToClipboardTxt = (value) => {
        navigator.clipboard.writeText(value);
        message.success('Copied successfully!!', 3);
    }

    const statusValidator = (_, value) => {
        if (value !== undefined && value !== null && value !== "" && value?.length !== 0 && value?.[0] !== '') {
            // console.log(value.length < 16)
            // if (value.length < 19) {
            //     return Promise.reject(new Error("Please Enter Full Card Number"));
            // }
        } else {
            return Promise.reject(new Error("Please select status"));
        }
        return Promise.resolve();
    }

    const style = {
        color:!allEditAccess ? 'rgba(32, 29, 29, 0.87)' :""
    };
    return (
        <Fragment>
            <div className='custom-flex-row justify-content-end'>
                <Button type='primary' loading={formLoading} onClick={handleFormSubmit} disabled={disableButton} className='mr-20'>Save</Button>
                <div onClick={closeForm} className='cursor-pointer'>
                    <CloseOutlined style={{fontSize: 20}} />
                </div>
            </div>
            <Form
                onFinish={handleFormSubmit} 
                form={form}
                layout={'vertical'}
                scrollToFirstError={{
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'center',
                }}
                initialValues={{ status: 'Open' }} // Set the default value for the status 
            >

                <Form.Item 
                    className='baa-custom-label-style'
                    label={'Title'}
                    name="title" 
                    rules={[{ required: true, message: "Please select a title!" }]}
                >
                    <Select
                        style={{ width: "100%", color:'rgba(32, 29, 29, 0.87)'}}
                        onChange={(e)=>{handleChangeTitle(e)}}
                        placeholder="Please select a title"
                        className='baa-custom-select'
                        disabled={!allEditAccess}
                        showSearch={false}
                    >
                        <Option value='Add Payment'>Add Payment</Option>
                        <Option value='Buyer Review'>Buyer Review</Option>
                        <Option value='Call Artist'>Call Artist</Option>
                        <Option value='Call Client'>Call Client</Option>
                        <Option value='Job Review'>Job Review</Option>
                        <Option value='Order Review'>Order Review</Option>
                        <Option value='Quiet OTP'>Quiet OTP</Option>
                        <Option value='Seller Review' >Seller Review</Option>
                        <Option value='Other'>Other</Option>
                    </Select>
                </Form.Item>
                {
                    title === 'Other' ? 
                    <Form.Item 
                        className='baa-custom-label-style'
                        // label={'Title'}
                        name="other_title" 
                        rules={[{ required: true, message: "Please select a title!" }]}
                    >
                        <Input
                            placeholder={'Title'}
                            onChange={(e)=>{setOtherTitle(e.target.value)}} 
                            className='baa-custom-input'
                            disabled={!allEditAccess}
                            style={style}
                        />
                    </Form.Item> : null
                }
                <Form.Item 
                    className='baa-custom-label-style'
                    label={`Description`} 
                    name="description" 
                    // rules={[{ required: true, message: "Please enter a description!" }]}
                >
                    <TextArea rows={4}
                        autosize={{ minRows: 4, maxRows: 4 }} 
                        placeholder={"Description"}
                        style={style}
                        // onChange={(e)=>{updateFieldValue('txt_description',e.target.value, item.id)}} 
                        className='baa-textarea'
                        disabled={!allEditAccess}
                    />
                </Form.Item>
                {
                    title === 'Other' ? 
                    <Form.Item 
                        className='baa-custom-label-style'
                        label={'Type'}
                        name="type" 
                        rules={[{ required: true, message: "Please select a type!" }]}
                    >
                        <Select
                            style={{ width: "100%" }}
                            onChange={(e)=>{setCSType(e)}}
                            placeholder="Please select a type"
                            className='baa-custom-select'
                            disabled={!allEditAccess}
                            showSearch={false}
                        >
                            <Option value='Buyer Id'>Buyer Id</Option>
                            <Option value='Job Id'>Job Id</Option>
                            <Option value='Order Id'>Order Id</Option>
                            <Option value='Seller Id'>Seller Id</Option>
                            <Option value='Other'>Other</Option>
                        </Select>
                    </Form.Item> : null
                }
                <div className='mb-10'>
                    {
                        csType === 'Seller Id' ?
                        <div>
                            {
                                (data.length !== 0 && data.seller_email !== null)  ||(sellerEmail != null  && sellerEmail !== undefined )? 
                                <div>
                                    <p>
                                        Seller Email: <a href={data.length !== 0 ?  `${url +data.seller_email}` : `${url +sellerEmail}`} target={'_blank'}>{data.length !== 0 ? data.seller_email : sellerEmail}</a>
                                        <ConversationLink text={data.length !== 0 ? data.seller_email : sellerEmail}/>
                                        <CopyText value={data.length !== 0 ? data.seller_email : sellerEmail} title={"Seller Email"} />
                                    </p>
                                </div>
                                : null
                            }
                        </div> :
                        csType === 'Buyer Id' ?
                        <div>
                          
                            {
                                (data.length !== 0 && data.buyer_email !== null)   ||(userEmail != null  && userEmail !== undefined ) ? 
                                <div>
                                   <p> 
                                        User Email: <a href={data.length !== 0 ?  `${url +data.buyer_email}` : `${url +userEmail}`} target={'_blank'}>{data.length !== 0 ? data.buyer_email : userEmail}</a> 
                                        <ConversationLink text={data.length !== 0 ?  data.buyer_email : userEmail}/>
                                        <CopyText value={data.length !== 0 ? data.buyer_email : userEmail} title={"Buyer Email"} />
                                    </p>
                                </div>
                                : null
                            }
                        </div> :
                        csType === 'Job Id' ?
                        <div>
                            <p>Job Id:{data.length !== 0 ? data.job_id : jobId}</p>
                            {
                                (data.length !== 0 && data.buyer_email !== null)  ||(userEmail != null  && userEmail !== undefined ) ? 
                                <div>
                                    <p> 
                                        User Email: <a href={data.length !== 0 ?  `${url +data.buyer_email}` : `${url +userEmail}`} target={'_blank'}>{data.length !== 0 ? data.buyer_email : userEmail}</a>
                                        <ConversationLink text={data.length !== 0 ?  data.buyer_email : userEmail}/>
                                        <CopyText value={data.length !== 0 ? data.buyer_email : userEmail} title={"Buyer Email"} />

                                    </p>

                                </div>
                                : null
                            }
                        </div> :
                        csType === 'Order Id' ? 
                        <div>
                            {
                                (data.length !== 0 && data.seller_email !== null)  ||(sellerEmail != null  && sellerEmail !== undefined )  ? 
                                <div>
                                    <p>
                                        Seller Email: <a href={data.length !== 0 ?  `${url +data.seller_email}` : `${url +sellerEmail}`} target={'_blank'}>{data.length !== 0 ? data.seller_email : sellerEmail}</a>
                                        <ConversationLink text={data.length !== 0 ? data.seller_email : sellerEmail}/>
                                        <CopyText value={data.length !== 0 ? data.seller_email : sellerEmail} title={"Seller Email"} />

                                    </p>        
                                </div>
                                : null
                            }
                            {
                                (data.length !== 0 && data.buyer_email !== null) ||(userEmail != null  && userEmail !== undefined ) ? 
                                <div>
                                    <p> 
                                        User Email:<a href={data.length !== 0 ?  `${url +data.buyer_email}` : `${url +userEmail}`} target={'_blank'}>{data.length !== 0 ? data.buyer_email : userEmail}</a> 
                                        <ConversationLink text={data.length !== 0 ? data.seller_email : sellerEmail}/>
                                        <CopyText value={data.length !== 0 ? data.buyer_email : userEmail} title={"User Email"} />

                                    </p>
                                </div>
                                : null
                            }
                            { (jobId != null  && jobId !== undefined) ||  (data.length !== 0 && data.job_id !== null) ?

                            <p>Job Id: {data.length !== 0 ? data.job_id : jobId}</p>
                              : null  }
                            { (orderId != null  && orderId !== undefined) ||  (data.length !== 0 && data.order_id !== null) ?
                                <p>
                                    Order Id: <a href={data.length !== 0 ?  `${url +data.order_id}` : `${url + orderId}`} target={'_blank'}>{data.length !== 0 ?  data.order_id : orderId}</a> 
                                    <ConversationLink text={data.length !== 0 ? data.order_id : orderId}/>
                                </p>
                              : null  }
                          
                        </div>
                        : 
                        csType === "Call Artist" ? <div>
                        {
                            (data.length !== 0 && data.seller_email !== null)  ||(sellerEmail != null  && sellerEmail !== undefined )? 
                            <div>
                                <p>
                                    Seller Email: <a href={data.length !== 0 ?  `${url +data.seller_email}` : `${url +sellerEmail}`} target={'_blank'}>{data.length !== 0 ? data.seller_email : sellerEmail}</a>
                                    <ConversationLink text={data.length !== 0 ? data.seller_email : sellerEmail}/>
                                    <CopyText value={data.length !== 0 ? data.seller_email : sellerEmail} title={"Seller Email"} />

                                </p>
                            </div>
                            : null
                        }
                    </div>  
                        : csType === "Call Client" ?
                    <div>
                            {
                                (data.length !== 0 && data.buyer_email !== null)  ||(userEmail != null  && userEmail !== undefined ) ? 
                                <div>
                                    <p> 
                                        User Email: <a href={data.length !== 0 ?  `${url +data.buyer_email}` : `${url +userEmail}`} target={'_blank'}>{data.length !== 0 ? data.buyer_email : userEmail}</a>
                                        <ConversationLink text={data.length !== 0 ?  data.buyer_email : userEmail}/>
                                        <CopyText value={data.length !== 0 ? data.buyer_email : userEmail} title={"Seller Email"} />
                                    </p>

                                </div>
                                : null
                            }
                    </div> 
                    : csType === "Quiet OTP" ?
                    <div>
                        {
                            (data.length !== 0 && data.seller_email !== null)  ||(sellerEmail != null  && sellerEmail !== undefined )? 
                            <div>
                                <p>
                                    Seller Email: <a href={data.length !== 0 ?  `${url +data.seller_email}` : `${url +sellerEmail}`} target={'_blank'}>{data.length !== 0 ? data.seller_email : sellerEmail}</a>
                                    <ConversationLink text={data.length !== 0 ? data.seller_email : sellerEmail}/>
                                    <CopyText value={data.length !== 0 ? data.seller_email : sellerEmail} title={"Seller Email"} />
                                </p>
                            </div>
                            : null
                        }
                        { (orderId != null  && orderId !== undefined) ||  (data.length !== 0 && data.order_id !== null) ?
                            <p>
                                Order Id: <a href={data.length !== 0 ?  `${url +data.order_id}` : `${url + orderId}`} target={'_blank'}>{data.length !== 0 ?  data.order_id : orderId}</a> 
                                <ConversationLink text={data.length !== 0 ? data.order_id : orderId}/>
                            </p>
                        : null  }
                    </div> : 
                    csType === "Add Payment" ? 
                        <>
                            { (orderId != null  && orderId !== undefined) ||  (data.length !== 0 && data.order_id !== null) ?
                            <p>
                                Order Id: <a href={data.length !== 0 ?  `${url +data.order_id}` : `${url + orderId}`} target={'_blank'}>{data.length !== 0 ?  data.order_id : orderId}</a> 
                                <ConversationLink text={data.length !== 0 ? data.order_id : orderId}/>
                                <CopyText value={data.length !== 0 ? data.order_id : orderId} title={"Order Id"} />

                            </p>
                        : null  }
                        {
                            (data.length !== 0 && data.seller_email !== null)  ||(sellerEmail != null  && sellerEmail !== undefined )? 
                            <div>
                                <p>
                                    Seller Email: <a href={data.length !== 0 ?  `${url +data.seller_email}` : `${url +sellerEmail}`} target={'_blank'}>{data.length !== 0 ? data.seller_email : sellerEmail}</a>
                                    <ConversationLink text={data.length !== 0 ? data.seller_email : sellerEmail}/>
                                    <CopyText value={data.length !== 0 ? data.seller_email : sellerEmail} title={"Seller Email"} />
                                </p>
                            </div>
                            : null
                        }
                        </>
                       : null
                    }
                </div>
                <Form.Item 
                    className='baa-custom-label-style'
                    label={'Assignee'}
                    name="assignee" 
                    rules={[{ required: true, message: "Please select a Assignee!" }]}
                >
                    <Select
                        mode="multiple"
                        style={{ width: "100%" }}
                        showSearch={false}
                        // onChange={(e)=>{handleChangeTitle(e)}}
                        placeholder="Please select a Assignee"
                        className='baa-custom-select'
                        disabled={!allEditAccess}
                    >
                        {adminList?.map((item)=> {
                            return <Option key={item.admin_id} value={item.admin_id} >{item.email}</Option>
                        })}
                    </Select>
                        
                </Form.Item> 
                {!allEditAccess ? <Form.Item 
                    className='baa-custom-label-style'
                    label={'Other Assignee'}
                    name="other_assignee" 
                    >
                        <Select 
                        mode="multiple"
                        style={{ width: "100%" }}
                        // onChange={(e)=>{handleChangeTitle(e)}}
                        placeholder="Please select a Assignee"
                        className='baa-custom-select'
                        showSearch={false}
                    >
                        {adminList?.map((item)=> {
                            return <Option key={item.admin_id} value={item.admin_id} >{item.email}</Option>
                        })}
                    </Select>
                        
                </Form.Item> :null 
              }
                
                    
                <Form.Item 
                    className='baa-custom-label-style'
                    label={'Watch (Info: Task on your list or get notified once the assignee closed the task)'}
                    name="watch" 
                    
                    // rules={[{ required: true, message: "Please select a Assignee!" }]}
                >
                    <Select
                        mode="multiple"
                        style={{ width: "100%" }}
                        // onChange={(e)=>{handleChangeTitle(e)}}
                        placeholder="Please select a Assignee"
                        className='baa-custom-select'
                        disabled={!allEditAccess}
                        showSearch={false}
                    >
                        {adminList?.map((item)=> {
                            return <Option key={item.admin_id} value={item.email}>{item.email}</Option>
                        })}
                    </Select>
                </Form.Item> 
                <div className='custom-flex-row'>
                    <Form.Item 
                        className='baa-custom-label-style'
                        label={'Due Date'}
                        name="due_date" 
                        // rules={[{ required: true, message: "Please select a Due Date" }]}
                    >
                        <DatePicker 
                            // value={deadline_type === "on_date" && (deadline_date !== "0000-00-00 00:00:00" && deadline_date) ? moment(deadline_date) : null}
                            format={'DD-MM-YYYY'}
                            // defaultValue={deadline_date && deadline_date !== "0000-00-00 00:00:00" ? moment(deadline_date) : moment(new Date())}
                            placeholder={"Due Date"}
                            disabledDate={disabledDate}
                            // onChange={(e) => this.setState({ deadline_date: e })}
                            // onOpenChange={isOpen => this.handleDeadlineType("on_date", isOpen)}
                            className={`custom-date-picker-style project-date-custom`}
                            allowClear={false}
                            disabled={!allEditAccess}
                        />
                    </Form.Item> 
                    <Form.Item 
                        className='baa-custom-label-style ml-10'
                        label={'Action Date'}
                        name="action_date" 
                        // rules={[{ required: true, message: "Please select a Due Date" }]}
                    >
                        <DatePicker 
                            // value={deadline_type === "on_date" && (deadline_date !== "0000-00-00 00:00:00" && deadline_date) ? moment(deadline_date) : null}
                            format={'DD-MM-YYYY'}
                            // defaultValue={deadline_date && deadline_date !== "0000-00-00 00:00:00" ? moment(deadline_date) : moment(new Date())}
                            placeholder={"Action Date"}
                            disabledDate={disabledDate}
                            // onChange={(e) => this.setState({ deadline_date: e })}
                            // onOpenChange={isOpen => this.handleDeadlineType("on_date", isOpen)}
                            className={`custom-date-picker-style project-date-custom`}
                            allowClear={false}
                        />
                    </Form.Item> 
                    <Form.Item 
                        className='baa-custom-label-style ml-10'
                        label={'Priority'}
                        name="tag" 
                        // rules={[{ required: true, message: "Please select a tag!" }]}
                    >
                        <Radio.Group 
                            size="medium"
                            className='project__radio-btn-grp'
                            disabled={!allEditAccess}
                        >
                            <Radio.Button value="High" className='pj-measure-category'>High</Radio.Button>
                            <Radio.Button value="Medium" className='pj-measure-category'>Medium</Radio.Button>
                            <Radio.Button value="Low" className='pj-measure-category'>Low</Radio.Button>
                        </Radio.Group>
                    </Form.Item> 
                </div>
                <Form.Item
                    className='baa-custom-label-style'
                    label="Status"
                    name="status"
                    rules={[{ required: true, message: "Please select status" }]}
                >
                    <Radio.Group
                    size="medium"
                    className='project__radio-btn-grp mt-10'
                    >
                    <Radio.Button value="Open" className='pj-measure-category'>Open</Radio.Button>
                    <Radio.Button value="Closed" className='pj-measure-category'>Closed</Radio.Button>
                    <Radio.Button value="Work In Progress" className='pj-measure-category'>Work In Progress</Radio.Button>
                    <Radio.Button value="Needs Attention" className='pj-measure-category'>Needs Attention</Radio.Button>
                    </Radio.Group>
                </Form.Item>
            </Form>
            <div className='mt-10 mb-10'>
                <p className='p-m-txt mb-10'>Adding Notes as - <Badge count={`${loggeedInUserData?.email?.split('@')[0]} - ${ currentDateTime.format('DD-MM-YYYY HH:mm:ss')}`} style={{ backgroundColor: '#52c41a', textTransform: 'capitalize' }} /></p>
                <TextArea  
                    onChange={(e) => setNotesTxt(e.target.value)}
                    rows={3}
                    value={notesTxt}
                />
            </div>
            {data?.notes !== ''&& data?.notes !== undefined && data?.notes !== null ?
                <div className='mb-10 mt-10'>
                    <p className='p-m-txt mb-10'>Old Notes</p>
                    <div className='old_notes'>
                        <p className='old_notes_content'>
                        {parse(data.notes == null ? "" :  data.notes?.replace(/\n/g, "<br/>"))}
                        </p>
                    </div>
                </div>
            : ""}
            <p className='p-m-txt mb-10'>Attach any screenshots</p>
            <AttachmentAction 
                customClassName={'custom-btn  custom-btn__regular btn__outline-secondary'}
                getImageList={imagesData}
                imageChangeHandler={imageChangeHandler}
                actionButtonDisableHandler={actionButtonDisableHandler}
                source={'task-form'}
                sellerDetails={loggeedInUserData}
            />
            <Button type='primary' loading={formLoading} onClick={handleFormSubmit} disabled={disableButton} className='mt-20'>Save</Button>
        </Fragment>
    )
}

export default CSTaskForm;