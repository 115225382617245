import Axios from 'axios';
var config = require("../../utils/generalconfig.json");

export const GET_ADMIN_CONVERSATION_LIST = "GET_ADMIN_CONVERSATION_LIST"
export const GET_ADMIN_CONVERSATION = "GET_ADMIN_CONVERSATION"
export const MARK_ADMIN_CONVERSATION_AS_READ = "MARK_ADMIN_CONVERSATION_AS_READ"
export const COMMON_CONVERSATION_CHANGES = "COMMON_CONVERSATION_CHANGES"
export const SEND_PAYMENT_REMINDER_REQUEST = "SEND_PAYMENT_REMINDER_REQUEST"


let baseurl = config.api_prod_url;
if (window.location.hostname === 'admin.bookanartist.co') {
    baseurl = config.api_prod_url
} else if (window.location.hostname === 'admin.hireanartist.com.au') {
    baseurl = config.api_stage_url
} else if (window.location.hostname === 'admin.hireastreetartist.co.uk') {
    baseurl = config.api_stage_url_v2
} else if (window.location.hostname === 'localhost') {
    baseurl = config.api_local_url
}
// /////////
function handleUnAuthorised() {
    let source = window.location.pathname
    if (source !== '/' && source !== '/logout') {
        source = '?source=' + source
    } else {
        source = ''
    }
    window.location.href = '/' + source
}


// get conversation list
export function getAdminConversationList(post) {
    return dispatch => {
        return Axios.post('api/customer_360', post, { baseURL: baseurl })
            .then(response => {
                const data = response.data || [];
                dispatch(requestAdminConversationList(data));
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 403) {
                        handleUnAuthorised();
                        // dispatch(push('/')); // Uncomment if redirection is needed
                    } else {
                        console.error(error);
                    }
                }
            });
    };
}

function requestAdminConversationList(response) {
    return {
        type: GET_ADMIN_CONVERSATION_LIST,
        get_admin_conversation_list: response
    }
}

export function emptyAdminConversationList() {
    return dispatch => {
        dispatch( {
            type: GET_ADMIN_CONVERSATION_LIST,
            get_admin_conversation_list: []
        })
    }
}

// get conversation data

export function getAdminConversation(post) {
    return dispatch => {
        return Axios.post('api/customer_360_conversation', post, { baseURL: baseurl })
            .then(response => {
                const data = response.data || [];
                dispatch(requestAdminConversation(data));
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 403) {
                        handleUnAuthorised();
                        // dispatch(push('/')); // Uncomment if redirection is needed
                    } else {
                        console.error(error);
                    }
                }
            });
    };
}

function requestAdminConversation(response) {
    return {
        type: GET_ADMIN_CONVERSATION,
        get_admin_conversation: response
    }
}

export function emptyAdminConversation() {
    return dispatch => {
        dispatch( {
            type: GET_ADMIN_CONVERSATION,
            get_admin_conversation: []
        })
    }
}

// mark conversation read
export function markConversationRead(post) {
    return dispatch => {
        return Axios.post('api/handle_message_read', post, { baseURL: baseurl })
            .then(response => {
                const data = response.data || [];
                dispatch(requestMarkConversationRead(data));
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 403) {
                        handleUnAuthorised();
                        // dispatch(push('/')); // Uncomment if redirection is needed
                    } else {
                        console.error(error);
                    }
                }
            });
    };
}

function requestMarkConversationRead(response) {
    return {
        type: MARK_ADMIN_CONVERSATION_AS_READ,
        mark_conversation_as_read: response
    }
}


export function emptyMarkConversationRead() {
    return dispatch => {
        dispatch( {
            type: MARK_ADMIN_CONVERSATION_AS_READ,
            mark_conversation_as_read: []
        })
    }
}


export function CommonConversationMessageChanges(post) {
    return dispatch => {
        return Axios.post('api/common_conversation_message_changes', post, { baseURL: baseurl })
            .then(response => {
                const data = response.data || [];
                dispatch(sendAlertReq(data));
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 403) {
                        handleUnAuthorised();
                        // dispatch(push('/')); // Uncomment if redirection is needed
                    } else {
                        console.error(error);
                    }
                }
            });
    };
}

function sendAlertReq(response) {
    return {
        type: COMMON_CONVERSATION_CHANGES,
        common_conversation_changes: response
    }
}

export function emptyCommonConversationChanges(){
  return {
    type: COMMON_CONVERSATION_CHANGES,
    common_conversation_changes: []
  }
}

export function SendPaymentReminderRequest(post) {
    return dispatch => {
        return Axios.post('api/send_payment_reminder', post, { baseURL: baseurl })
            .then(response => {
                const data = response.data || [];
                dispatch(SendPaymentReminderRequestReq(data));
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 403) {
                        handleUnAuthorised();
                        // dispatch(push('/')); // Uncomment if redirection is needed
                    } else {
                        console.error(error);
                    }
                }
            });
    };
}

function SendPaymentReminderRequestReq(response) {
    return {
        type: SEND_PAYMENT_REMINDER_REQUEST,
        send_payment_reminder_request: response
    }
}
