import {
  SELLER_DETAILS,
  BUYER_DETAILS,
  RECEIVE_LOGIN,
  LOGOUT,
  DASHBOARD_DETAILS,
  SERVICE_PRICE,
  SERVICE_DETAILS,
  CAPTURE_EMAILS,
  FILTER_LOGS,
  QUOTE_CALC_LOGS,
  GET_ALLCOUNTRY,
  GET_STATE_BY_COUNTRY,
  GET_All_CATEGORY,
  SELLER_SEQUENCE,
  SELLER_STATUS,
  UPDATE_SELLER_STATUS,
  ORDER_LIST,
  ORDER_STATUS_LIST,
  UPDATE_ORDER_STATUS,
  INSERT_ARTIST_PAYMENT,
  UPDATE_ARTIST_PAYMENT,
  UPDATE_SELLER_PAYMENT,
  DELETE_ARTIST_PAYMENT,
  UPDATE_BUYER_DETAILS,
  UPLOAD_FILE_S3UPLOAD,
  UPDATE_ARTIST_RECEIPT,
  UPDATE_SELLER_VISIBLE,
  UPDATE_QUOTE_CALC_VISIBLE,
  CITY_CONTENT,
  UPDATE_SEARCH_PAGE_CONTENT,
  SELLER_USING_ONE_BUYER,
  UPDATE_LOST_STATUS,
  TAG_DEATILS,
  UPDATE_TAG,
  DELETE_TAG,
  ADD_TAG,
  UPDATE_ORDER_COMMISSION,
  INSERT_BUYER_PAYMENT,
  UPDATE_BUYER_PAYMENT,
  DELETE_BUYER_PAYMENT,
  EDIT_ARTIST_EMAIL,
  UPDATE_ARTIST_NOTE,
  HARD_DEL_SELLER,
  UPDATE_ACTIVE_STATUS,
  JOBS_LIST,
  INSERT_ORDER_NOTES,
  REVIEW_LIST,
  UPDATE_JOB_DETAILS,
  CITY_LIST_ACTIVE,
  SELLER_LIST_FULL_PROGRESS,
  INSERT_SEARCH_PAGE_CONTENT,
  SEARCH_PAGE_CONTENT,
  UPDATE_SEARCH_PAGE_DYNAMIC,
  FAQ_CONTENT,
  CREATE_COUNTRY,
  CREATE_STATE,
  UPDATE_COUNTRY_DETAILS,
  UPDATE_STATE_DETAILS,
  CREATE_BANK_DETAILS,
  UPDATE_BANK_DETAILS,
  GET_ALL_BANK_DETAILS,
  SELLER_ALL_DETAILS,
  UPDATE_MULTIPLE_ORDER_STATUS,
  BUYER_LIMIT_DETAILS,
  ORDER_DETAILS_BY_ID,
  ORDER_CONVERSATION_BY_ID,
  OTHER_ORDER_DETAILS,
  ADD_BUYER_NOTES,
  SEND_PAYMENT_EMAIL,
  QUOTE_USING_ORDER,
  INVOICE_FROM_DASHBOARD,
  GET_ORDER_BY_DETAILS,
  INSERT_INVOICE_DATA,
  INSERT_MULTIPLE_INVOICE_DATA,
  UPLOAD_INVOICE_S3,
  JOB_DETAILS,
  UPDATE_CS_STATUS,
  ORDER_DETAILS,
  ORDER_CONVERSATION_WITH_ANALYTICS,
  CS_CONVERSATION_READ_STATUS,
  CS_CONVERSATION_UNREAD_STATUS,
  GET_REPORTING,
  GET_ALL_FLAG_DETAILS,
  ADD_FLAG_ORDER_DETAILS,
  UPDATE_FLAG_ORDER_DETAILS,
  UPDATE_CS_FOLLOW_UP,
  GET_CITY_LAT_LONG,
  UPDATE_CS_CUSTOMER_TYPE,
  UPDATE_SELLER_NOTES,
  SELLER_EXPERIENCE_LIST,
  ORDER_CALL_RECORDING,
  All_ORDER_CALL_RECORDING_LIST,
  SELLER_BUYER_REVIEWS,
  UPDATE_CS_FOLLOW_UP_DATE,
  POJ_EMAIL_TRIGGER,
  ORDER_BASED_AUTO_LOGIN_LINK,
  NOT_INTERESTED_ORDER_LIST,
  UPDATE_SELLER_DETAILS_COMMON,
  FLAG_ORDER_DETAILS_LIST,
  ORDER_DETAILS_BY_BUYER,
  MARKED_AS_READ_MULTIPLE_ORDER,
  GET_SINGLE_ORDER_DETAILS,
  GET_EMAIL_TEMPLATE_LIST,
  ADD_EMAIL_TEMPLATE,
  UPDATE_EMAIL_TEMPLATE,
  SEND_EMAIL_TEMPLATE_VIA_EMAIL,
  GET_REVIEWS,
  CONVERT_TO_POJ,
  UPDATE_MARKETING_NOTES,
  UPDATE_ORDER_CALL_FLAG,
  UPDATE_SELLER_STRIPE_VISIBLE,
  GET_EMAIL_LOGS,
  GET_SEND_EMAIL_TEMPLATE_BY_ID,
  EXPORT_SELLER_ALL_DATA,
  DELETED_ARTIST_LIST,
  SPAM_ORDER_JOB_LIST,
  UPDATE_SELLER_ROLE,
  YATIN_MEETING_NOTIFICATION,
  CONVERT_TO_POJ_V2,
  GET_TIP_INFORMATION,
  COUPON_LIST,
  HARD_DELETE_BUYER,
  NOTIFY_ARTIST_FOR_JOB,
  DELETE_JOB,
  HOLD_PAYMENT_LIST,
  ALL_PAYMENT_LIST,
  SELLER_SUBSCRIPTION_DATA_LIST,
  SET_SELLER_SUBSCRIPTION,
  UPDATE_BUYER_COMMON_DETAILS,
  CANCEL_SUBSCRIPTION,
  ALL_SUBSCRIPTION_LIST,
  SEARCH_TERMS,
  CREATE_SEARCH_TERM_PAGE,
  UPDATE_SEARCH_TERM_PAGE,
  JOB_LIVE_PUSH_NOTIFICATION,
  BUYER_SUBSCRIPTION_DATA_LIST,
  ADD_PARTICIPANT_IN_CONVERSATION,
  GET_USER_NOTIFICATION_SETTING,
  SET_USER_NOTIFICATION_SETTING,
  GET_EMAIL_NOTIFICATION_SETTING,
  SET_EMAIL_NOTIFICATION_SETTING,
  MANAGE_SELLER_BADGE,
  COUNTER_OTP_REPORT,
  QUITE_OTP_ORDER_REPORT,
  ADD_QUITE_OTP,
  REMOVE_QUITE_OTP,
  GET_INVOICE_DETAIL_BY_INVOICE_COUNT,
  UPDATE_INVOICE_DETAIL,
  GET_PAYMENT_TIMELINE,
  GIFT_CARD_LIST,
  REFERRAL_USERS_LIST,
  ADD_INVOICE,
  ADD_MULTIPLE_INVOICE,
  SELLER_DETAIL_FOR_INVOICE,
  DELETE_OFFER,
  SELLER_ALL_DETAIL,
  SELLER_NOTIFICATION_LOGS,
  SELLER_TRANSACTION_LOGS,
  SELLER_EXPERIENCE_LOGS,
  SELLER_PAYMENT_LOGS,
  SELLER_ORDER_DETAIL_LOGS,
  SELLER_VISIBILITY_LOGS,
  SELLER_CS_LOGS,
  SELLER_ORDER_COUNT,
  MEMBER_SHIP_LOGS,
  MIXPANEL_SELLER_DATA,
  GET_SELLER_COUNT_WITH_STATE_BY_COUNTRY,
  UPDATE_ORDER_DETAILS,
  ALL_BADGES_LIST,
  CHECK_NEAR_BY_TOP_ARTIST,
  ASSIGN_SELLER_MEMBERSHIP,
  COMMON_OTP_SELLER_DETAIL,
  BAA_ASSISTANT_CONVERSATION,
  CREATE_BAA_ASSISTANT_MESSAGE,
  CANVAS_OTP_REPORT,
  CHECK_SELLER_CURRENT_MEMBERSHIP,
  ALREADY_PAID_RESPONSE,
  ALREADY_PAID_RESPONSE_DATA,
  BUY_CANVAS_DATA,
  MEMBER_SHIP_PROGRESS_TRACKING,
  CTA_ORDER_LIST,
  SELLER_DASHBOARD_DETAILS,
  CHECK_STRIPE_STATUS,
  APPROVE_HOLD_ORDER,
  BAA_OPENAI_V1,
  AWS_BEDROCK_V1,
  CREATE_BAA_COPILOT_MESSAGE,
  GET_BAA_COPILOT_MESSAGES,
  UPDATE_ORDER_CONVERSATION,
  BUYER_ORDERS_COPILOT_ANALYSIS,
  REMOVE_QUITE_OTP_V2,
  BUYER_ORDER_CONVERSATION,
  BUYER_ORDER_CONVERSATION_BY_USER_ID,
  GET_SSR_SELLER_LIST,
  GET_SSR_SELLER_DETAIL_LIST,
  GET_NEW_STATE_BY_COUNTRY,GET_NEW_ALL_COUNTRY,SAVE_ADMIN_DEVICE_ID,GET_INVOICING_ORDER_EXPORT

} from '../actions/actions';


function commonReducer(state = {
  seller_details: [], login_user_data: [], buyer_details: [], logout_data: [], dashboard_details: [], service_price_list: [], capture_emails: [], filter_logs: [], quote_calc_logs: [], get_state_by_country: [], get_all_category: [], get_allcountry: [], seller_sequence: [], seller_status: [], update_seller_status: [], order_list: [], order_status_list: [], update_order_status: [], insert_artist_payment: [], update_artist_payment: [], update_seller_payment: [], delete_artist_payment: [], upload_file_s3upload: [], update_artist_receipt: [], update_seller_visible: [], update_quote_calc_visible: [], city_list: [], update_search_page_content: [], seller_using_one_buyer: [], update_lost_status: [], tag_details: [], update_tag: [], delete_tag: [], add_tag: [], update_order_commission: [], edit_artist_email: [], insert_buyer_payment: [], update_buyer_payment: [], delete_buyer_payment: [], update_artist_note: [], hard_del_seller: [], update_active_status: [], jobs_list: [], insert_order_notes: [], review_list: [], update_job_details: [], city_list_active: [], seller_list_full_progress: [], insert_search_page_content: [], search_page_content: [], update_search_page_dynamic: [], faq_content: [], create_country: [], create_state: [], update_country_details: [], update_state_details: [], create_bank_details: [], update_bank_details: [], get_all_bank_details: [], update_multiple_order_status: [], buyer_limit_details: [], order_details_by_id: [], order_conversation_by_id: [], other_order_details: [], add_buyer_notes: [], send_payment_email: [], quote_using_order: [], invoice_from_dashboard: [], get_order_by_details: [], insert_invoice_data: [], insert_multiple_invoice_data: [], upload_ivoice_s3: [], job_details: [], update_cs_status: [], get_all_flag_details: [], add_flag_order_details: [], update_flag_order_details: [],
  order_list_details : [], get_order_conversation_with_analytics : [], cs_conversation_read_status: [], cs_conversation_unread_status : [], update_cs_follow_up: [], get_reporting: [], get_city_lat_lan: [], update_cs_customer_type : [], update_seller_notes : [], seller_experience_list : [], order_call_recording : [], all_order_call_recording_list : [], update_cs_follow_up_date : [], poj_email_trigger: [], order_based_auto_login_link : [], seller_buyer_reviews : [], not_interested_order_list: [], update_seller_details_common: [],
  flag_order_details_list: [], order_list_details_by_buyer: [], mark_as_read_multiple_order: [], get_single_order_details: [], add_email_template: [], get_email_template_list: [], update_email_template: [],
  send_email_template_via_email: [], get_reviews: [], convert_to_poj: [], update_marketing_notes: [], update_order_call_flag: [], update_seller_stripe_visible: [], get_email_logs: [], get_send_email_template_by_id: [], export_seller_all_data: [], deleted_artist_list: [], spam_order_job_list: [], update_seller_role: [], yatin_meeting_notification: [], convert_to_poj_v2: [], suspend_buyer: [], get_coupon:[], get_tip_information: [], hard_delete_buyer: [], notify_artist_for_job: [], delete_job: [],get_hold_payment_list:[], get_all_payment_list: [], subscription_data_list: [], set_subscription: [], update_buyer_common_details: [], cancel_subscription: [],all_subscription_list: [], search_terms: [], create_search_term_page: [], update_search_term_page: [], job_live_push_notification: [], buyer_subscription_data_list: [], ADD_PARTICIPANT_IN_CONVERSATION: [], get_user_notification_setting: [], set_user_notification_setting: [],  get_email_notification_setting: [], set_email_notification_setting: [], manage_seller_badge: [], gift_card_list : [], referral_users_list : [], add_invoice :[], add_multiple_invoice:[], seller_detail_for_invoice:[] , delete_offer:[], counter_otp_report: [], remove_quite_otp: [], quite_otp_order_report: [], add_quite_otp: [],seller_all_detail : [], seller_notification_logs : [],seller_transaction_logs : [], seller_experience_logs : [],seller_payment_logs : [], seller_order_detail_logs : [],seller_visibility_logs : [], seller_cs_logs : [], seller_order_count : [],member_ship_logs:[],mixpanel_seller_data:[], get_seller_count_with_state_by_country:[],assign_seller_membership: [], update_order_details:[],all_badges_list:[],check_near_by_top_artist:[],baa_assistant_conversation:[],create_baa_assistant_message:[], canvas_otp_report:[], common_otp_seller_detail:[],check_seller_current_membership:[], already_paid_response:[],already_paid_response_data:[],buy_canvas_data:[],
  member_ship_progress_tracking:[], cta_order_list :[], seller_dashboard_details:[], check_stripe_status: [], approve_hold_order: [], baa_openai_v1: [], aws_bedrock_v1: [], create_baa_copilot_message: [], get_baa_copilot_messages: [], update_order_conversation :[],buyer_orders_copilot_analysis: [],remove_quite_otp_v2:[], buyer_order_conversation:[],buyer_order_conversation_by_user_id:[],get_ssr_seller_list :[],get_ssr_seller_detail_list:[] ,get_new_state_by_country :[], get_new_all_country :[], get_invoicing_order_export :[]

}, action) {

  switch (action.type) {
    case RECEIVE_LOGIN:
      return Object.assign({}, state, {
        login_user_data: action.login_user_data,
      });

    case SELLER_DETAILS:
      return Object.assign({}, state, {
        seller_details: action.seller_details,
      });

    case BUYER_DETAILS:
      return Object.assign({}, state, {
        buyer_details: action.buyer_details,
      });

    case CAPTURE_EMAILS:
      return Object.assign({}, state, {
        capture_emails: action.capture_emails,
      });

    case FILTER_LOGS:
      return Object.assign({}, state, {
        filter_logs: action.filter_logs,
      });

    case QUOTE_CALC_LOGS:
      return Object.assign({}, state, {
        quote_calc_logs: action.quote_calc_logs,
      });

    case DASHBOARD_DETAILS:
      return Object.assign({}, state, {
        dashboard_details: action.dashboard_details,
      });

    case SERVICE_PRICE:
      return Object.assign({}, state, {
        service_price_list: action.service_price_list,
      });

    case SERVICE_DETAILS:
      return Object.assign({}, state, {
        service_details: action.service_details,
      });

    case LOGOUT:
      return Object.assign({}, state, {
        logout_data: action.logout_data,
      });

    case GET_ALLCOUNTRY:
      return Object.assign({}, state, {
        get_allcountry: action.get_allcountry,
      });

    case GET_STATE_BY_COUNTRY:
      return Object.assign({}, state, {
        get_state_by_country: action.get_state_by_country,
      });

    case GET_All_CATEGORY:
      return Object.assign({}, state, {
        get_all_category: action.get_all_category,
      });

    case SELLER_SEQUENCE:
      return Object.assign({}, state, {
        seller_sequence: action.seller_sequence,
      });

    case SELLER_STATUS:
      return Object.assign({}, state, {
        seller_status: action.seller_status,
      });

    case UPDATE_SELLER_STATUS:
      return Object.assign({}, state, {
        update_seller_status: action.update_seller_status,
      });

    case ORDER_LIST:
      return Object.assign({}, state, {
        order_list: action.order_list,
      });

    case ORDER_STATUS_LIST:
      return Object.assign({}, state, {
        order_status_list: action.order_status_list,
      });

    case UPDATE_ORDER_STATUS:
      return Object.assign({}, state, {
        update_order_status: action.update_order_status,
      });

    case UPDATE_BUYER_DETAILS:
      return Object.assign({}, state, {
        update_buyer_details: action.update_buyer_details,
      });

    case INSERT_ARTIST_PAYMENT:
      return Object.assign({}, state, {
        insert_artist_payment: action.insert_artist_payment,
      });

    case UPDATE_ARTIST_PAYMENT:
      return Object.assign({}, state, {
        update_artist_payment: action.update_artist_payment,
      });

    case UPDATE_SELLER_PAYMENT:
      return Object.assign({}, state, {
        update_seller_payment: action.update_seller_payment,
      });

    case DELETE_ARTIST_PAYMENT:
      return Object.assign({}, state, {
        delete_artist_payment: action.delete_artist_payment,
      });

    case UPLOAD_FILE_S3UPLOAD:
      return Object.assign({}, state, {
        upload_file_s3upload: action.upload_file_s3upload,
      });

    case UPDATE_ARTIST_RECEIPT:
      return Object.assign({}, state, {
        update_artist_receipt: action.update_artist_receipt,
      });

    case UPDATE_SELLER_VISIBLE:
      return Object.assign({}, state, {
        update_seller_visible: action.update_seller_visible,
      });

    case UPDATE_QUOTE_CALC_VISIBLE:
      return Object.assign({}, state, {
        update_quote_calc_visible: action.update_quote_calc_visible,
      });

    case CITY_CONTENT:
      return Object.assign({}, state, {
        city_list: action.city_list,
      });

    case UPDATE_SEARCH_PAGE_CONTENT:
      return Object.assign({}, state, {
        update_search_page_content: action.update_search_page_content,
      });

    case SELLER_USING_ONE_BUYER:
      return Object.assign({}, state, {
        seller_using_one_buyer: action.seller_using_one_buyer,
      });

    case UPDATE_LOST_STATUS:
      return Object.assign({}, state, {
        update_lost_status: action.update_lost_status,
      });

    case TAG_DEATILS:
      return Object.assign({}, state, {
        tag_details: action.tag_details,
      });

    case UPDATE_TAG:
      return Object.assign({}, state, {
        update_tag: action.update_tag,
      });

    case DELETE_TAG:
      return Object.assign({}, state, {
        delete_tag: action.delete_tag,
      });

    case ADD_TAG:
      return Object.assign({}, state, {
        add_tag: action.add_tag,
      });

    case UPDATE_ORDER_COMMISSION:
      return Object.assign({}, state, {
        update_order_commission: action.update_order_commission,
      });

    case INSERT_BUYER_PAYMENT:
      return Object.assign({}, state, {
        insert_buyer_payment: action.insert_buyer_payment,
      });

    case UPDATE_BUYER_PAYMENT:
      return Object.assign({}, state, {
        update_buyer_payment: action.update_buyer_payment,
      });

    case DELETE_BUYER_PAYMENT:
      return Object.assign({}, state, {
        delete_buyer_payment: action.delete_buyer_payment,
      });
    case EDIT_ARTIST_EMAIL:
      return Object.assign({}, state, {
        edit_artist_email: action.edit_artist_email,
      });

    case UPDATE_ARTIST_NOTE:
      return Object.assign({}, state, {
        update_artist_note: action.update_artist_note,
      });

    case HARD_DEL_SELLER:
      return Object.assign({}, state, {
        hard_del_seller: action.hard_del_seller,
      });

    case UPDATE_ACTIVE_STATUS:
      return Object.assign({}, state, {
        update_active_status: action.update_active_status,
      });

    case JOBS_LIST:
      return Object.assign({}, state, {
        jobs_list: action.jobs_list,
      });

    case REVIEW_LIST:
      return Object.assign({}, state, {
        review_list: action.review_list,
      });

    case UPDATE_JOB_DETAILS:
      return Object.assign({}, state, {
        update_job_details: action.update_job_details,
      });

    case CITY_LIST_ACTIVE:
      return Object.assign({}, state, {
        city_list_active: action.city_list_active,
      });

    case INSERT_ORDER_NOTES:
      return Object.assign({}, state, {
        insert_order_notes: action.insert_order_notes,
      });

    case SELLER_LIST_FULL_PROGRESS:
      return Object.assign({}, state, {
        seller_list_full_progress: action.seller_list_full_progress,
      });

    case INSERT_SEARCH_PAGE_CONTENT:
      return Object.assign({}, state, {
        insert_search_page_content: action.insert_search_page_content,
      });

    case SEARCH_PAGE_CONTENT:
      return Object.assign({}, state, {
        search_page_content: action.search_page_content,
      });

    case UPDATE_SEARCH_PAGE_DYNAMIC:
      return Object.assign({}, state, {
        update_search_page_dynamic: action.update_search_page_dynamic,
      });

    case FAQ_CONTENT:
      return Object.assign({}, state, {
        faq_content: action.faq_content,
      });

    case CREATE_COUNTRY:
      return Object.assign({}, state, {
        create_country: action.create_country,
      });

    case CREATE_STATE:
      return Object.assign({}, state, {
        create_state: action.create_state,
      });

    case UPDATE_COUNTRY_DETAILS:
      return Object.assign({}, state, {
        update_country_details: action.update_country_details,
      });

    case UPDATE_STATE_DETAILS:
      return Object.assign({}, state, {
        update_state_details: action.update_state_details,
      });

    case CREATE_BANK_DETAILS:
      return Object.assign({}, state, {
        create_bank_details: action.create_bank_details,
      });

    case UPDATE_BANK_DETAILS:
      return Object.assign({}, state, {
        update_bank_details: action.update_bank_details,
      });

    case GET_ALL_BANK_DETAILS:
      return Object.assign({}, state, {
        get_all_bank_details: action.get_all_bank_details,
      });

    case SELLER_ALL_DETAILS:
      return Object.assign({}, state, {
        seller_all_details: action.seller_all_details,
      });
    case UPDATE_MULTIPLE_ORDER_STATUS:
      return Object.assign({}, state, {
        update_multiple_order_status: action.update_multiple_order_status,
      });

    case BUYER_LIMIT_DETAILS:
      return Object.assign({}, state, {
        buyer_limit_details: action.buyer_limit_details,
      });

    case ORDER_DETAILS_BY_ID:
      return Object.assign({}, state, {
        order_details_by_id: action.order_details_by_id,
      });

    case ORDER_CONVERSATION_BY_ID:
      return Object.assign({}, state, {
        order_conversation_by_id: action.order_conversation_by_id,
      });

    case OTHER_ORDER_DETAILS:
      return Object.assign({}, state, {
        other_order_details: action.other_order_details,
      });

    case ADD_BUYER_NOTES:
      return Object.assign({}, state, {
        add_buyer_notes: action.add_buyer_notes,
      });

    case SEND_PAYMENT_EMAIL:
      return Object.assign({}, state, {
        send_payment_email: action.send_payment_email,
      });

    case QUOTE_USING_ORDER:
      return Object.assign({}, state, {
        quote_using_order: action.quote_using_order,
      });

    case INVOICE_FROM_DASHBOARD:
      return Object.assign({}, state, {
        invoice_from_dashboard: action.invoice_from_dashboard,
      });

    case GET_ORDER_BY_DETAILS:
      return Object.assign({}, state, {
        get_order_by_details: action.get_order_by_details,
      });

    case INSERT_INVOICE_DATA:
      return Object.assign({}, state, {
        insert_invoice_data: action.insert_invoice_data,
      });

    case INSERT_MULTIPLE_INVOICE_DATA:
      return Object.assign({}, state, {
        insert_multiple_invoice_data: action.insert_multiple_invoice_data,
      });

    case UPLOAD_INVOICE_S3:
      return Object.assign({}, state, {
        upload_ivoice_s3: action.upload_ivoice_s3,
      });

    case JOB_DETAILS:
      return Object.assign({}, state, {
        job_details: action.job_details,
      });

    case UPDATE_CS_STATUS:
      return Object.assign({}, state, {
        update_cs_status: action.update_cs_status,
      });

    case GET_ALL_FLAG_DETAILS:
      return Object.assign({}, state, {
        get_all_flag_details: action.get_all_flag_details,
      });

    case ADD_FLAG_ORDER_DETAILS:
      return Object.assign({}, state, {
        add_flag_order_details: action.add_flag_order_details,
      });

    case UPDATE_FLAG_ORDER_DETAILS:
      return Object.assign({}, state, {
        update_flag_order_details: action.update_flag_order_details,
      });

    case UPDATE_CS_FOLLOW_UP:
      return Object.assign({}, state, {
        update_cs_follow_up: action.update_cs_follow_up,
      });

    case GET_REPORTING:
      return Object.assign({}, state, {
        get_reporting: action.get_reporting,
      });

    case GET_CITY_LAT_LONG:
      return Object.assign({}, state, {
        get_city_lat_lan: action.get_city_lat_lan,
      });

    case ORDER_DETAILS:
      return Object.assign({}, state, {
        order_list_details: action.order_list_details,
      });

    case ORDER_CONVERSATION_WITH_ANALYTICS:
      return Object.assign({}, state, {
        get_order_conversation_with_analytics:
          action.get_order_conversation_with_analytics,
      });

    case CS_CONVERSATION_READ_STATUS:
      return Object.assign({}, state, {
        cs_conversation_read_status: action.cs_conversation_read_status,
      });

    case CS_CONVERSATION_UNREAD_STATUS:
      return Object.assign({}, state, {
        cs_conversation_unread_status: action.cs_conversation_unread_status,
      });

    case UPDATE_CS_CUSTOMER_TYPE:
      return Object.assign({}, state, {
        update_cs_customer_type: action.update_cs_customer_type,
      });

    case UPDATE_SELLER_NOTES:
      return Object.assign({}, state, {
        update_seller_notes: action.update_seller_notes,
      });

    case SELLER_EXPERIENCE_LIST:
      return Object.assign({}, state, {
        seller_experience_list: action.seller_experience_list,
      });

    case ORDER_CALL_RECORDING:
      return Object.assign({}, state, {
        order_call_recording: action.order_call_recording,
      });

    case All_ORDER_CALL_RECORDING_LIST:
      return Object.assign({}, state, {
        all_order_call_recording_list: action.all_order_call_recording_list,
      });

    case SELLER_BUYER_REVIEWS:
      return Object.assign({}, state, {
        seller_buyer_reviews: action.seller_buyer_reviews,
      });

    case UPDATE_CS_FOLLOW_UP_DATE:
      return Object.assign({}, state, {
        update_cs_follow_up_date: action.update_cs_follow_up_date,
      });

    case POJ_EMAIL_TRIGGER:
      return Object.assign({}, state, {
        poj_email_trigger: action.poj_email_trigger,
      });

    case ORDER_BASED_AUTO_LOGIN_LINK:
      return Object.assign({}, state, {
        order_based_auto_login_link: action.order_based_auto_login_link,
      });

    case NOT_INTERESTED_ORDER_LIST:
      return Object.assign({}, state, {
        not_interested_order_list: action.not_interested_order_list,
      });

    case UPDATE_SELLER_DETAILS_COMMON:
      return Object.assign({}, state, {
        update_seller_details_common: action.update_seller_details_common,
      });

    case FLAG_ORDER_DETAILS_LIST:
      return Object.assign({}, state, {
        flag_order_details_list: action.flag_order_details_list,
      });

    case ORDER_DETAILS_BY_BUYER:
      return Object.assign({}, state, {
        order_list_details_by_buyer: action.order_list_details_by_buyer,
      });

    case MARKED_AS_READ_MULTIPLE_ORDER:
      return Object.assign({}, state, {
        mark_as_read_multiple_order: action.mark_as_read_multiple_order,
      });

    case GET_SINGLE_ORDER_DETAILS:
      return Object.assign({}, state, {
        get_single_order_details: action.get_single_order_details,
      });

    case ADD_EMAIL_TEMPLATE:
      return Object.assign({}, state, {
        add_email_template: action.add_email_template,
      });

    case GET_EMAIL_TEMPLATE_LIST:
      return Object.assign({}, state, {
        get_email_template_list: action.get_email_template_list,
      });

    case UPDATE_EMAIL_TEMPLATE:
      return Object.assign({}, state, {
        update_email_template: action.update_email_template,
      });

    case SEND_EMAIL_TEMPLATE_VIA_EMAIL:
      return Object.assign({}, state, {
        send_email_template_via_email: action.send_email_template_via_email,
      });

    case GET_REVIEWS:
      return Object.assign({}, state, {
        get_reviews: action.get_reviews,
      });

    case CONVERT_TO_POJ:
      return Object.assign({}, state, {
        convert_to_poj: action.convert_to_poj,
      });

    case UPDATE_MARKETING_NOTES:
      return Object.assign({}, state, {
        update_marketing_notes: action.update_marketing_notes,
      });

    case UPDATE_ORDER_CALL_FLAG:
      return Object.assign({}, state, {
        update_order_call_flag: action.update_order_call_flag,
      });

    case UPDATE_SELLER_STRIPE_VISIBLE:
      return Object.assign({}, state, {
        update_seller_stripe_visible: action.update_seller_stripe_visible,
      });

    case GET_EMAIL_LOGS:
      return Object.assign({}, state, {
        get_email_logs: action.get_email_logs,
      });

    case GET_SEND_EMAIL_TEMPLATE_BY_ID:
      return Object.assign({}, state, {
        get_send_email_template_by_id: action.get_send_email_template_by_id,
      });

    case EXPORT_SELLER_ALL_DATA:
      return Object.assign({}, state, {
        export_seller_all_data: action.export_seller_all_data,
      });

    case DELETED_ARTIST_LIST:
      return Object.assign({}, state, {
        deleted_artist_list: action.deleted_artist_list,
      });

    case SPAM_ORDER_JOB_LIST:
      return Object.assign({}, state, {
        spam_order_job_list: action.spam_order_job_list,
      });

    case UPDATE_SELLER_ROLE:
      return Object.assign({}, state, {
        update_seller_role: action.update_seller_role,
      });

    case YATIN_MEETING_NOTIFICATION:
      return Object.assign({}, state, {
        yatin_meeting_notification: action.yatin_meeting_notification,
      });

    case CONVERT_TO_POJ_V2:
      return Object.assign({}, state, {
        convert_to_poj_v2: action.convert_to_poj_v2,
      });

    case COUPON_LIST:
      return Object.assign({}, state, {
        get_coupon: action.get_coupon,
      });

    case HARD_DELETE_BUYER:
      return Object.assign({}, state, {
        hard_delete_buyer: action.hard_delete_buyer,
      });

    case GET_TIP_INFORMATION:
      return Object.assign({}, state, {
        get_tip_information: action.get_tip_information,
      });

    case NOTIFY_ARTIST_FOR_JOB:
      return Object.assign({}, state, {
        notify_artist_for_job: action.notify_artist_for_job,
      });

    case DELETE_JOB:
      return Object.assign({}, state, {
        delete_job: action.delete_job,
      });

    case HOLD_PAYMENT_LIST:
      return Object.assign({}, state, {
        get_hold_payment_list: action.get_hold_payment_list,
      });

    case ALL_PAYMENT_LIST:
      return Object.assign({}, state, {
        get_all_payment_list: action.get_all_payment_list,
      });

    case SELLER_SUBSCRIPTION_DATA_LIST:
      return Object.assign({}, state, {
        seller_subscription_data_list: action.seller_subscription_data_list,
      });

    case SET_SELLER_SUBSCRIPTION:
      return Object.assign({}, state, {
        set_seller_subscription: action.set_seller_subscription,
      });

    case UPDATE_BUYER_COMMON_DETAILS:
      return Object.assign({}, state, {
        update_buyer_common_details: action.update_buyer_common_details,
      });

    case CANCEL_SUBSCRIPTION:
      return Object.assign({}, state, {
        cancel_subscription: action.cancel_subscription,
      });

    case ALL_SUBSCRIPTION_LIST:
      return Object.assign({}, state, {
        all_subscription_list: action.all_subscription_list,
      });

    case SEARCH_TERMS:
      return Object.assign({}, state, {
        search_terms: action.search_terms,
      });

    case CREATE_SEARCH_TERM_PAGE:
      return Object.assign({}, state, {
        create_search_term_page: action.create_search_term_page,
      });

    case UPDATE_SEARCH_TERM_PAGE:
      return Object.assign({}, state, {
        update_search_term_page: action.update_search_term_page,
      });

    case JOB_LIVE_PUSH_NOTIFICATION:
      return Object.assign({}, state, {
        job_live_push_notification: action.job_live_push_notification,
      });

    case BUYER_SUBSCRIPTION_DATA_LIST:
      return Object.assign({}, state, {
        buyer_subscription_data_list: action.buyer_subscription_data_list,
      });

    case ADD_PARTICIPANT_IN_CONVERSATION:
      return Object.assign({}, state, {
        add_participant_in_conversation: action.add_participant_in_conversation,
      });

    case GET_USER_NOTIFICATION_SETTING:
      return Object.assign({}, state, {
        get_user_notification_setting: action.get_user_notification_setting,
      });
    case SET_USER_NOTIFICATION_SETTING:
      return Object.assign({}, state, {
        set_user_notification_setting: action.set_user_notification_setting,
      });
    case GET_EMAIL_NOTIFICATION_SETTING:
      return Object.assign({}, state, {
        get_email_notification_setting: action.get_email_notification_setting,
      });
    case SET_EMAIL_NOTIFICATION_SETTING:
      return Object.assign({}, state, {
        set_email_notification_setting: action.set_email_notification_setting,
      });
    case GET_INVOICE_DETAIL_BY_INVOICE_COUNT:
      return Object.assign({}, state, {
        get_invoice_detail_by_invoice_count:
          action.get_invoice_detail_by_invoice_count,
      });
    case UPDATE_INVOICE_DETAIL:
      return Object.assign({}, state, {
        update_invoice_detail: action.update_invoice_detail,
      });
    case GET_PAYMENT_TIMELINE:
      return Object.assign({}, state, {
        get_payment_timeline: action.get_payment_timeline,
      });

    case MANAGE_SELLER_BADGE:
      return Object.assign({}, state, {
        manage_seller_badge: action.manage_seller_badge,
      });
    case GIFT_CARD_LIST:
      return Object.assign({}, state, {
        gift_card_list: action.gift_card_list,
      });
    case REFERRAL_USERS_LIST:
      return Object.assign({}, state, {
        referral_users_list: action.referral_users_list,
      });
    case ADD_INVOICE:
      return Object.assign({}, state, {
        add_invoice: action.add_invoice,
      });
    case ADD_MULTIPLE_INVOICE:
      return Object.assign({}, state, {
        add_multiple_invoice: action.add_multiple_invoice,
      });
    case SELLER_DETAIL_FOR_INVOICE:
      return Object.assign({}, state, {
        seller_detail_for_invoice: action.seller_detail_for_invoice,
      });
    case DELETE_OFFER:
      return Object.assign({}, state, {
        delete_offer: action.delete_offer,
      });
    case COUNTER_OTP_REPORT:
      return Object.assign({}, state, {
        counter_otp_report: action.counter_otp_report,
      });

    case QUITE_OTP_ORDER_REPORT:
      return Object.assign({}, state, {
        quite_otp_order_report: action.quite_otp_order_report,
      });

    case ADD_QUITE_OTP:
      return Object.assign({}, state, {
        add_quite_otp: action.add_quite_otp,
      });

    case REMOVE_QUITE_OTP:
      return Object.assign({}, state, {
        remove_quite_otp: action.remove_quite_otp,
      });
    case SELLER_ALL_DETAIL:
      return Object.assign({}, state, {
        seller_all_detail: action.seller_all_detail,
      });
    case SELLER_NOTIFICATION_LOGS:
      return Object.assign({}, state, {
        seller_notification_logs: action.seller_notification_logs,
      });
    case SELLER_TRANSACTION_LOGS:
      return Object.assign({}, state, {
        seller_transaction_logs: action.seller_transaction_logs,
      });
    case SELLER_EXPERIENCE_LOGS:
      return Object.assign({}, state, {
        seller_experience_logs: action.seller_experience_logs,
      });
    case SELLER_PAYMENT_LOGS:
      return Object.assign({}, state, {
        seller_payment_logs: action.seller_payment_logs,
      });
    case SELLER_ORDER_DETAIL_LOGS:
      return Object.assign({}, state, {
        seller_order_detail_logs: action.seller_order_detail_logs,
      });
    case SELLER_VISIBILITY_LOGS:
      return Object.assign({}, state, {
        seller_visibility_logs: action.seller_visibility_logs,
      });
    case SELLER_CS_LOGS:
      return Object.assign({}, state, {
        seller_cs_logs: action.seller_cs_logs,
      });
    case SELLER_ORDER_COUNT:
      return Object.assign({}, state, {
        seller_order_count: action.seller_order_count,
      });
    case MEMBER_SHIP_LOGS:
      return Object.assign({}, state, {
        member_ship_logs: action.member_ship_logs,
      });
    case MIXPANEL_SELLER_DATA:
      return Object.assign({}, state, {
        mixpanel_seller_data: action.mixpanel_seller_data,
      });
    case GET_SELLER_COUNT_WITH_STATE_BY_COUNTRY:
      return Object.assign({}, state, {
        get_seller_count_with_state_by_country: action.get_seller_count_with_state_by_country,
      });
    case ASSIGN_SELLER_MEMBERSHIP:
      return Object.assign({}, state, {
        assign_seller_membership: action.assign_seller_membership,
      });
      
    case UPDATE_ORDER_DETAILS:
      return Object.assign({}, state, {
        update_order_details: action.update_order_details,
      });
    case ALL_BADGES_LIST:
      return Object.assign({}, state, {
        all_badges_list: action.all_badges_list,
      });
    case CHECK_NEAR_BY_TOP_ARTIST:
      return Object.assign({}, state, {
        check_near_by_top_artist: action.check_near_by_top_artist,
      });
    case COMMON_OTP_SELLER_DETAIL:
      return Object.assign({}, state, {
        common_otp_seller_detail: action.common_otp_seller_detail,
      });
    case BAA_ASSISTANT_CONVERSATION:
      return Object.assign({}, state, {
        baa_assistant_conversation: action.baa_assistant_conversation,
      });
    case CREATE_BAA_ASSISTANT_MESSAGE:
      return Object.assign({}, state, {
        create_baa_assistant_message: action.create_baa_assistant_message,
      });
    case CANVAS_OTP_REPORT:
      return Object.assign({}, state, {
        canvas_otp_report: action.canvas_otp_report,
      });
    case CHECK_SELLER_CURRENT_MEMBERSHIP:
      return Object.assign({}, state, {
        check_seller_current_membership: action.check_seller_current_membership,
      });
    case ALREADY_PAID_RESPONSE:
      return Object.assign({}, state, {
        already_paid_response: action.already_paid_response,
      });
    case ALREADY_PAID_RESPONSE_DATA:
      return Object.assign({}, state, {
        already_paid_response_data: action.already_paid_response_data,
      });
    case BUY_CANVAS_DATA:
      return Object.assign({}, state, {
        buy_canvas_data: action.buy_canvas_data,
      });
    case MEMBER_SHIP_PROGRESS_TRACKING:
      return Object.assign({}, state, {
        member_ship_progress_tracking: action.member_ship_progress_tracking,
      });
    case CTA_ORDER_LIST:
      return Object.assign({}, state, {
        cta_order_list: action.cta_order_list,
      });
    case SELLER_DASHBOARD_DETAILS:
      return Object.assign({}, state, {
        seller_dashboard_details: action.seller_dashboard_details,
      });
    case CHECK_STRIPE_STATUS:
      return Object.assign({}, state, {
        check_stripe_status: action.check_stripe_status,
      });
    case APPROVE_HOLD_ORDER:
      return Object.assign({}, state, {
        approve_hold_order: action.approve_hold_order,
      });
    case BAA_OPENAI_V1:
      return Object.assign({}, state, {
        baa_openai_v1: action.baa_openai_v1,
      });
    case AWS_BEDROCK_V1:
      return Object.assign({}, state, {
        aws_bedrock_v1: action.aws_bedrock_v1,
      });
    case CREATE_BAA_COPILOT_MESSAGE:
      return Object.assign({}, state, {
        create_baa_copilot_message: action.create_baa_copilot_message,
      });
    case GET_BAA_COPILOT_MESSAGES:
      return Object.assign({}, state, {
        get_baa_copilot_messages: action.get_baa_copilot_messages,
      });
    case BUYER_ORDERS_COPILOT_ANALYSIS:
      return Object.assign({}, state, {
        buyer_orders_copilot_analysis: action.buyer_orders_copilot_analysis,
      });
    case UPDATE_ORDER_CONVERSATION:
      return Object.assign({}, state, {
        update_order_conversation: action.update_order_conversation,
      });
    case REMOVE_QUITE_OTP_V2:
      return Object.assign({}, state, {
        remove_quite_otp_v2: action.remove_quite_otp_v2,
      });
    case BUYER_ORDER_CONVERSATION:
      return Object.assign({}, state, {
        buyer_order_conversation: action.buyer_order_conversation,
      });
    case BUYER_ORDER_CONVERSATION_BY_USER_ID:
      return Object.assign({}, state, {
        buyer_order_conversation_by_user_id: action.buyer_order_conversation_by_user_id,
      });
    case GET_SSR_SELLER_LIST:
      return Object.assign({}, state, {
        get_ssr_seller_list: action.get_ssr_seller_list,
      });
    case GET_SSR_SELLER_DETAIL_LIST:
      return Object.assign({}, state, {
        get_ssr_seller_detail_list: action.get_ssr_seller_detail_list,
      });
    case GET_NEW_STATE_BY_COUNTRY:
      return Object.assign({}, state, {
        get_new_state_by_country: action.get_new_state_by_country,
      });
    case GET_NEW_ALL_COUNTRY:
      return Object.assign({}, state, {
        get_new_all_country: action.get_new_all_country,
      });
    case SAVE_ADMIN_DEVICE_ID:
      return Object.assign({}, state, {
        save_admin_device_id: action.save_admin_device_id,
      });
    case GET_INVOICING_ORDER_EXPORT:
      return Object.assign({}, state, {
        get_invoicing_order_export: action.get_invoicing_order_export,
      });
    default:
      return state;
  }
}
//  : [],
export default commonReducer