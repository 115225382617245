import { GET_WESOVER_BUY_NOW_ORDER_LIST, } from '../../actions/wescoverActions/orderAction';

function OrderReducer(state = {
    get_wescover_buy_now_order_list: [],
}, action) {

    switch (action.type) {
        case GET_WESOVER_BUY_NOW_ORDER_LIST:
            return Object.assign({}, state, {
                get_wescover_buy_now_order_list: action.get_wescover_buy_now_order_list,
            });
        default:
            return state;
    }
}
    
export default OrderReducer;