import Axios from 'axios';
const config = require("../../../utils/generalconfig.json");

export const GET_WESOVER_SELLER_LIST = 'GET_WESOVER_SELLER_LIST';
export const GET_MAKERS_DETAILS = 'GET_MAKERS_DETAILS';
export const GET_ALL_REVIEWS = 'GET_ALL_REVIEWS';
export const UPDATE_REVIEWS = 'UPDATE_REVIEWS';

let baseurl = config.api_prod_url;
if (window.location.hostname === 'admin.bookanartist.co') {
    baseurl = config.api_prod_url
} else if (window.location.hostname === 'admin.hireanartist.com.au') {
    baseurl = config.api_stage_url
} else if (window.location.hostname === 'admin.hireastreetartist.co.uk') {
    baseurl = config.api_stage_url_v2
} else if (window.location.hostname === 'localhost') {
    baseurl = config.api_local_url
}

function handleUnAuthorised() {
    let source = window.location.pathname
    if (source !== '/' && source !== '/logout') {
        source = '?source=' + source
    } else {
        source = ''
    }
    window.location.href = '/' + source
}

// get voice token
export function getWescoverSellerList(req) {
    return dispatch => {
        return Axios.post('api/seller_list',{...req}, { baseURL: baseurl })
            .then(response => {
                const data = response.data || [];
                dispatch(requestGetWescoverSellerList(data));
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 403) {
                        handleUnAuthorised();
                        // dispatch(push('/')); // Uncomment if redirection is needed
                    } else {
                        console.error(error);
                    }
                }
            });
    };
}

function requestGetWescoverSellerList(response) {
    return {
        type: GET_WESOVER_SELLER_LIST,
        get_wesover_seller_list: response
    }
}

export function EmptyGetWescoverSellerList(response) {
 return {
    type: GET_WESOVER_SELLER_LIST,
    get_wesover_seller_list: []
  }
}

export function getMakersDetail(req) {
    return dispatch => {
        return Axios.post('api/makers_detail',{...req}, { baseURL: baseurl })
            .then(response => {
                const data = response.data || [];
                dispatch(requestGetMakersDetail(data));
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 403) {
                        handleUnAuthorised();
                        // dispatch(push('/')); // Uncomment if redirection is needed
                    } else {
                        console.error(error);
                    }
                }
            });
    };
}

function requestGetMakersDetail(response) {
    return {
        type: GET_MAKERS_DETAILS,
        get_makers_details: response
    }
}

export function getAllReviews(req) {
    return dispatch => {
        return Axios.post('api/seller_reviews',{...req}, { baseURL: baseurl })
            .then(response => {
                const data = response.data || [];
                dispatch(requestAllReviews(data));
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 403) {
                        handleUnAuthorised();
                        // dispatch(push('/')); // Uncomment if redirection is needed
                    } else {
                        console.error(error);
                    }
                }
            });
    };
}

function requestAllReviews(response) {
    return {
        type: GET_ALL_REVIEWS,
        get_all_reviews: response
    }
}

export function updateReviews(req) {
    return dispatch => {
        return Axios.post('api/update_reviews',{...req}, { baseURL: baseurl })
            .then(response => {
                const data = response.data || [];
                dispatch(requestUpdateReviews(data));
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 403) {
                        handleUnAuthorised();
                        // dispatch(push('/')); // Uncomment if redirection is needed
                    } else {
                        console.error(error);
                    }
                }
            });
    };
}

function requestUpdateReviews(response) {
    return {
        type: UPDATE_REVIEWS,
        update_reviews: response
    }
}
