import React from 'react'
import { CopyOutlined } from '@ant-design/icons';
import { message } from 'antd';

const index = (props) => {
    const handleCopyToClipboard = (value, title) => {
        // copy input text to clipboard
        navigator.clipboard.writeText(value)

        message.success(`${title} copied to clipboard`)
    }
    return (
        <span className='ml-5 mr-5'>
            <CopyOutlined
                className='pscd-list-icon'
                onClick={e => handleCopyToClipboard(props?.value, props?.title)}
            />

        </span>
    )
}

export default index
